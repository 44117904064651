.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
      height: 100vh;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../../../public/background1.jpg');
        filter: blur(1px);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1; /* Place it behind other content */
        width: 100%;
        height: 100%;
      }
      .container{
        border: 1px solid #ddd;
        background-color: rgba($color: #000000, $alpha: 0.8);
        padding: 20px 10px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        h1 {
          font-size: 2em;
          color: #ffffff;
        }
      
        .buttonContainer {
          gap: 5px;
          display: flex;
          flex-direction: column; /* Mobilde sütun düzeni */
      
          .button {
            text-decoration: none;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 10px 20px;
            margin: 10px 0;
            font-size: 16px;
            cursor: pointer;
            border: none;
            border-radius: 5px;
            background-color: #3498db;
            color: #fff;
            transition: background-color 0.3s;
      
            .icon {
              margin-right: 10px;
              color: #fff;
            }
      
            &:hover {
              background-color: #2980b9;
            }
          }
      }
    
    
    }
  
    @media (min-width: 768px) {
      /* Tablet ekranlar ve üstü için stil ayarları */
      .buttonContainer {
        flex-direction: row; /* Tablet ve üstü için satır düzeni */
      }
    }
  
    @media (min-width: 992px) {
      /* Desktop ekranlar ve üstü için stil ayarları */
      .buttonContainer {
        margin-top: 0; /* Boşlukları azalt */
      }
    }
  }
  