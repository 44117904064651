// Değişkenler
$primary-color: #3498db;
$secondary-color: #2ecc71;
$background-color: #f5f5f5;
$text-color: #333;
$font-stack: 'Arial', sans-serif;
$button-bg: #e74c3c;
$button-hover-bg: darken($button-bg, 10%);
$box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
      height: 100vh;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../../../public/background2.jpg');
        filter: blur(1px);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1; /* Place it behind other content */
        width: 100%;
      }
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: $box-shadow;
  margin-bottom: 20px;
  text-align: center;

  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 10px;
  }

  p {
    font-family: $font-stack;
    color: $text-color;
    margin: 5px 0;

    &:first-of-type {
      font-weight: bold;
      font-size: 1.2rem;
    }
  }

  button {
    background-color: $button-bg;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-family: $font-stack;
    cursor: pointer;
    transition: background-color 0.3s;

    &:hover {
      background-color: $button-hover-bg;
    }
  }
}

.contents {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: $box-shadow;

  .title {
    font-family: $font-stack;
    color: $primary-color;
    font-size: 1.5rem;
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    color: $primary-color;
    font-family: $font-stack;
    font-size: 1rem;
    margin: 10px 0;
    transition: color 0.3s;

    &:hover {
      color: $secondary-color;
    }
  }
}
