.container {
  text-align: center;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 300px;
  background-color: rgba($color: #000000, $alpha: 0.7);
  .loadingOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Make sure it appears above other elements */
  }
  
  .loadingSpinner {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
}
h1{
  color: white;
}
.formContainer {
  margin-top: 20px;
}
.fullName{
  display: flex;
  gap: 5px;
}
.formGroup {
  margin-bottom: 15px;

  label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: white;
    text-align: left;
    font-size: 12px;
  }

  input {
    width: 100%;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-sizing: border-box;
  }
}
.loginButton {
  width: 100%;
  padding: 10px;
  background-color: #3498db;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;

  &:hover {
    background-color: #2980b9;
  }
}
.forgotPassword{
  text-decoration: none;
  display: block;
  text-align: end;
  margin-top: 2px;
  color: rgb(165, 198, 255);
  font-size: 12px;
  &:hover{
    color: #3498db;
  }
}
.footerText {
  margin-top: 10px;
  font-size: 14px;
  color: white;
  a {
    margin-left: 5px;
    color: #3498db;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}