.mainContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    width: 100vw;
      height: 100vh;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url('../../../../public/background2.jpg');
        filter: blur(1px);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        z-index: -1; /* Place it behind other content */
        width: 100%;
        height: 100%;
      }
  }
  