.contentContainer {
    padding: 20px;
  background-color: #f9f9f9;
  background-image: url('../../../../public/background3.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  
    .loadingOverlay {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7); /* Adjust the opacity as needed */
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000; /* Make sure it appears above other elements */
      }
      
      .loadingSpinner {
        border: 4px solid #f3f3f3;
        border-top: 4px solid #3498db;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        animation: spin 1s linear infinite;
      }
      
      @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
      }
  
    .container {
      display: flex;
      flex-direction: column;
      gap: 20px;
  
      .studentsWithContentContainer, 
      .gamesWithContentContainer, 
      .studentsWithHighestPointsContainer {
        background-color: #fff;
        opacity: 0.9;
        padding: 20px;
        border-radius: 8px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  
        h2 {
          font-size: 1.5rem;
          margin-bottom: 10px;
        }
  
        span {
          display: block;
          margin-bottom: 5px;
        }
      }
  
      .gamesWithContentContainer {
        .gameResult {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          background-color: #f1f1f1;
          border-radius: 4px;
          margin-bottom: 10px;
  
          span {
            flex: 1;
            text-align: center;
  
            &:first-child {
              flex: 2;
              text-align: left;
            }
          }
        }
      }
  
      .studentsWithHighestPointsContainer {
        .highestPoint {
          display: flex;
          justify-content: space-between;
          padding: 10px;
          background-color: #f1f1f1;
          border-radius: 4px;
          margin-bottom: 10px;
  
          span {
            flex: 1;
            text-align: center;
  
            &:first-child {
              flex: 2;
              text-align: left;
            }
          }
        }
      }
    }
  
    h1 {
      font-size: 2rem;
      margin-bottom: 20px;
    }
  }
  